.custom-radio {
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
  cursor: pointer;
  color: #5e718b;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  /* background-color: #ebf6ff; */
  border: 1px solid #7b83eb;
  border-radius: 50%;
}

.custom-radio input:checked + .custom-radio-button {
  background-color: #7b83eb;
}

.custom-radio input:checked + .custom-radio-button:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ebf6ff;
}
