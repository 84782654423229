.toggle-switch {
  position: relative;
  width: 46px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #7b83eb;
  border-radius: 20px;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner:before,
.toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  color: white;
  box-sizing: border-box;
}
.toggle-switch-inner:before {
  content: "Yes";
  padding-left: 5px;
  font-size: 12px;
  background-color: #4b54a2;
  color: #fff;
}
.toggle-switch-disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
.toggle-switch-disabled:before {
  background-color: #7b83eb;
  cursor: not-allowed;
}
.toggle-switch-inner:after {
  content: "No";
  padding-right: 8px;
  background-color: #7b83eb;
  color: #fff;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 18px;
  margin: 1.5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}
