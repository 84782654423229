body * {
  box-sizing: border-box;
}
.text-label-color {
  color: #5e718b;
}
input[required] + label:after {
  content: "*";
  color: red;
}
.MuiStepLabel-root.MuiStepLabel-vertical {
  padding: 0px 0px !important;
}
.MuiStepConnector-root.MuiStepConnector-vertical .MuiStepConnector-line {
  min-height: 60px !important;
  border-color: #7b83eb !important;
}
.QontoStepIcon-circle {
  width: 56px;
  height: 56px;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root text {
  display: none;
}
.css-381x02-MuiSvgIcon-root-MuiStepIcon-root {
  border-radius: 50%;
  border: 1px solid #7b83eb;
}
.MuiStepLabel-vertical .MuiStepLabel-labelContainer .MuiStepLabel-label {
  margin-left: 11px !important;
  opacity: 0.5 !important;
  color: #4b54a2 !important;
  font-size: 20px !important;
  font-style: normal;
  font-family: "DM Sans";
  font-weight: 700 !important;
  line-height: normal;
}
.MuiStepLabel-vertical
  .MuiStepLabel-labelContainer
  .MuiStepLabel-label.Mui-active {
  opacity: 1 !important;
  color: #4b54a2 !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700 !important;
  font-family: "DM Sans";
  line-height: normal;
}
/* .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-completed
{
  fill:#ebf6ff;
} */
/* .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root circle{
  fill: #ebf6ff;
} */
/* .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active circle {
  fill: #7b83eb;
} */
.MuiStepConnector-root {
  margin-left: 30px !important;
}
::-webkit-scrollbar {
  background-color: #e1edf6;
  width: 8px;
}
::-webkit-scrollbar-track {
  background-color: #e1edf6;
}
::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 16px;
  /* border: 4px solid #babac0; */
}

.image-small {
  width: 25px;
  height: 25px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.flag-icon svg {
  border-radius: 40px;
}
.image-large {
  width: 69px;
  height: 69px;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #5e718b;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 19.2px */
}
.rec-pagination,
.rec-arrow-up,
.rec-arrow-down {
  display: none !important;
}
.dydPIA,
.rec-slider-container {
  margin: 0px !important;
}

.myDiv {
  width: auto;
  height: auto;
  float: left;
  margin: 15px;
}

.myDiv.fullscreen {
  z-index: 9999;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
}

.animateTransitions {
  /* transition: all 1s; */
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}
.bg-ground {
  background-color: "#FFFFF";
}

.fsm {
  /* margin: 10vh 5vw; */
  background-color: white;
  height: 100px;
  flex: 1;
  color: #f5f5f5;
  font-size: 1.5rem;
  border-radius: 10px;
  transition: 1s;
  cursor: pointer;
  opacity: 1;

  &.full-screen {
    height: 100vh;
    /* z-index: 100; */
    margin: 0;
    border-radius: 0;
    opacity: 1;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
  }
}

section {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  /* visibility: hidden; */
}
section.active {
  visibility: visible;
}
.dQiuok {
  padding: 0px 0px;
}
.MuiModal-root .MuiBox-root.css-55mepe {
  box-shadow: 0px 4px 27px 10px rgba(123, 131, 235, 0.1) !important;
  border-radius: 34px !important;
  border: 1.2px solid #8828ff !important;
  background: #f0f1ff !important;
}
.MuiTooltip-arrow {
  color: white !important;
}

.flag-item-wrapper {
  transition: all 0.3s;
}

.flag-item-wrapper:hover {
  opacity: 1;
}

.btn-set-active-item {
  display: inline-flex !important;
  width: fit-content;
  /* margin-top: 30px !important; */
  /* margin-bottom: 30px !important; */
}

.btn-print {
  position: absolute !important;
  top: 12px;
  right: 57px;
}

.img-fluid {
  max-width: 100%;
}

/* steps animation start */

.step-container-enter {
  opacity: 0;
  transform: translateY(100px);
}
.step-container-enter-active {
  opacity: 1;
  transition: all 400ms;
  transform: translateY(0);
}
.step-container-exit {
  opacity: 1;
}
.step-container-exit-active {
  opacity: 0;
  transition: all 400ms;
  transform: translateY(-100px);
  max-height: 0;
}

/* steps animation end */

@media screen and (max-height: 730px) {
  .details-form-wrapper {
    height: 72% !important;
  }
}
@media screen and (max-height: 800px) {
  .img-fluid {
    width: 500px;
  }
  .css-honmcm-MuiGrid-root > .MuiGrid-item {
    padding-top: 0px !important;
  }
}
.MuiTooltip-tooltip .MuiBox-root {
  display: flex;
  justify-content: center;
  align-items: "center";
}

.scroll-bar::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
}
.scroll-bar::-webkit-scrollbar-track {
  background-color: #fff;
}
.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #c9e8fd;
  border-radius: 16px;
  /* border: 4px solid #babac0; */
}

.country-select > .MuiSelect-select .country-name {
  display: none;
}

.country-select > .MuiSelect-select {
  display: flex;
  align-items: center;
  gap: 5px;
}

.selectPlanBtn .MuiBox-root:first-child > .MuiTypography-root {
  display: flex;
  align-items: center;
}

@media screen and (max-height: 790px) {
  .btn-fullscreen {
    right: 165px !important;
    bottom: 105px !important;
  }

  .btn-fullscreen--addons {
    right: 175px !important;
  }
}

.select-currency li {
  display: block !important;
}

@media screen and (max-height: 720px) {
  .summary-popup {
    top: 350px !important;
  }
}

@media print {
  .summary-popup-monthly-costs-section {
    max-height: none !important;
  }
}

@media screen and (max-width: 899px) {
  .stepBox {
    width: 100%;
    padding: 50px 0 25px;
  }
  .stepBox .MuiStepper-root {
    width: 100%;
    z-index: 1;
    align-items: flex-start;
    position: relative;
  }
  .stepBox .MuiStep-root {
    width: 25%;
    text-align: center;
  }
  .stepBox .MuiStepLabel-root {
    display: block;
  }
  .stepBox .MuiStepLabel-iconContainer {
    padding: 0;
    justify-content: center;
  }
  .stepBox .MuiStepLabel-iconContainer > div {
    height: auto;
  }
  .stepBox .MuiStepConnector-root {
    position: absolute;
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 !important;
    top: 22px;
    z-index: -1;
  }
  .stepBox .MuiStepConnector-line {
    border-color: #7b83eb;
  }
  .stepIcon {
    width: 42px !important;
    height: 42px !important;
  }
  .stepIcon svg {
    width: 19px;
  }
  .stepBox .MuiStepLabel-labelContainer {
    margin-top: 4px;
  }
  .MuiBox-root .details-form-container {
    padding: 22px 16px 24px;
    display: flex;
    flex-direction: column;
    border-radius: 16px !important;
  }
  .MuiBox-root .MuiTypography-h4 {
    font-size: 26px;
  }
  .details-form-container .css-1g1rxmx {
    gap: 16px;
  }
  .details-form-wrapper {
    flex-grow: 1;
  }
  .details-form-wrapper .MuiTypography-root {
    margin-left: 0;
  }
  .details-form-wrapper .MuiInputBase-input {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .details-form-wrapper .MuiStack-root .MuiBox-root > span {
    display: none;
  }
  .country-select .MuiSelect-select .MuiTypography-root {
    margin-bottom: 0;
  }
  .country-select {
    margin-right: 5px;
  }
  .css-jjt7u7 {
    height: 100% !important;
    min-height: calc(100vh - 180px);
  }
  .stepFooter .MuiBox-root {
    width: 100%;
  }
  .stepFooter .MuiBox-root.justifyEnd {
    justify-content: flex-end !important;
  }
  .stepFooter .MuiBox-root.justifyBetween {
    justify-content: space-between !important;
  }
  .stepFooter .MuiBox-root .backBtn {
    flex-direction: row-reverse;
  }
  .stepFooter .MuiBox-root .backBtn svg {
    display: inline-block;
    order: 1;
    transform: rotate(-90deg);
    margin-right: 4px;
  }
  .stepFooter .MuiBox-root button {
    border-radius: 14.5px;
    font-size: 14px;
    padding: 9px 14px;
  }
  .stepFooter .MuiBox-root .nextBtn svg {
    display: inline-block;
    order: 1;
    transform: rotate(-90deg);
    margin-left: 4px;
  }
  .main {
    min-height: 100vh;
    overflow: auto;
  }
  .customRadio {
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    margin-top: 16px !important;
  }
  .customRadio .MuiFormGroup-root {
    margin: 8px 0 24px;
  }
  .customRadio .custom-radio {
    padding-left: 30px;
    padding-right: 18px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .customRadio .custom-radio .MuiTypography-root {
    margin-bottom: 0;
  }
  .signUp-btn-wrap {
    text-align: center;
  }
  .signUp-btn {
    color: #7b83eb !important;
    background-color: transparent !important;
    padding: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
  }
  .MuiStep-root.Mui-completed .MuiStepLabel-root .Mui-completed {
    font-weight: 700;
  }
  .errorPopup {
    padding: 30px 16px !important;
    text-align: center;
    width: 100% !important;
    max-width: 324px !important;
    border-radius: 20px !important;
  }
  .errorPopup .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
  }
  .errorPopup .closeBtn button {
    padding: 10px;
  }
  .errorPopup > img {
    width: 50px !important;
    height: 50px !important;
  }
  .errorPopup .heading {
    font-size: 26px;
    font-weight: 700;
    line-height: 31px;
    margin-top: 10px;
  }
  #modal-modal-description {
    margin-top: 8px;
    margin-right: 0;
    font-size: 16px;
    line-height: 23px;
  }
  .addMore-btn button {
    width: 100%;
    font-size: 14px;
    padding: 9px 10px;
  }
  .main {
    background-size: cover !important;
    background-attachment: fixed !important;
    background-position: right !important;
  }
  .toggle-switch {
    min-width: 46px;
    margin-left: 10px;
  }
  .blockItem .MuiTypography-root {
    display: block;
  }
  .blockItem .MuiTypography-root svg {
    position: relative;
    top: 5px;
  }
  .toggle-switch-switch {
    height: 18px !important;
    right: 26px !important;
    margin: 1px !important;
  }
  .toggle-switch-inner:before,
  .toggle-switch-inner:after {
    line-height: 180% !important;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px !important;
  }
  .selectPlanBtn {
    justify-content: space-between;
  }
  .selectPlanBtn .MuiBox-root {
    width: 49%;
    margin: 0;
    margin-top: 8px;
    padding: 10px;
    min-height: 76px;
  }
  .selectPlanBtn .MuiBox-root:first-child > .MuiTypography-root {
    display: block;
    font-size: 14px;
    color: #5e718b;
  }
  .selectPlanBtn .MuiBox-root .MuiTypography-root {
    margin-bottom: 0;
  }
  .infoBlock .MuiTypography-root {
    font-size: 12px;
  }
  .infoBlock .MuiButtonBase-root {
    padding: 0 12px 0 0;
  }
  .flagSliderPart .MuiButtonBase-root {
    display: none;
  }
  .flagSlider .flagSliderPart .rec-carousel {
    height: 100% !important;
  }
  .flagSlider .flagSliderPart .rec-carousel .rec-slider {
    position: relative;
  }
  .flagSlider .flagSliderPart .rec-carousel-item .rec-item-wrapper {
    padding: 0 !important;
    margin-bottom: 16px;
  }
  .flagSliderPart .rec-carousel-item .rec-item-wrapper .flag-icon svg {
    width: 56px;
    height: 56px;
  }
  .flagSliderPart
    .rec-carousel-item
    .rec-item-wrapper
    .flag-item-wrapper
    .MuiTypography-root {
    margin-bottom: 0;
  }
  .flagBox .flag-item-wrapper .flag-icon svg {
    width: 56px;
    height: 56px;
  }
  .flagBox .flag-item-wrapper .MuiTypography-root {
    margin-bottom: 0;
    color: #5e718b;
  }
  .countryChangeBtn .MuiTypography-root {
    margin-bottom: 0;
    cursor: pointer;
  }
  .select-currency .MuiTypography-root {
    margin-bottom: 0;
  }
  .countryBlock {
    margin-top: 71px !important;
  }
  .countryName {
    display: none !important;
  }
  .numbersBox {
    margin: 0 -8px;
    max-width: 100% !important;
  }
  .numbersBox .MuiFormControl-root {
    width: 50%;
    margin: 0;
    padding: 0 8px;
    max-width: 164px;
    margin-bottom: 16px;
  }
  .numbersBox .MuiFormControl-root .MuiBox-root {
    margin: 0;
    width: 100%;
  }
  .numbersBox .MuiFormControl-root .MuiBox-root .MuiButtonBase-root {
    max-width: 39px;
    min-width: 39px;
  }
  .numbersBox .MuiFormControl-root .MuiBox-root .MuiInputBase-input {
    height: 26px;
  }
  .plan {
    width: 100%;
    max-width: 360px;
  }
  .plan .MuiFormControl-root {
    width: 100%;
  }
  .plan .singlePlanBox {
    justify-content: space-between;
  }
  .plan .singlePlanBox .MuiBox-root {
    width: 31.5%;
    margin: 0;
    padding: 10px;
    max-width: 110px;
  }
  .plan .singlePlanBox .MuiBox-root .MuiTypography-root {
    margin-bottom: 0;
  }
  .locationInput.MuiBox-root {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .tollFreeSwitch.MuiBox-root {
    margin-left: 0;
  }
  .tollFreeSwitch.MuiBox-root .MuiBox-root {
    width: 100%;
    justify-content: space-between;
    max-width: 340px;
  }
  .tollFreeSwitch.MuiBox-root .MuiBox-root .MuiTypography-root {
    margin-bottom: 0;
  }
  .numbersBox.needNumber {
    margin: 0 !important;
    max-width: 100% !important;
  }
  .numbersBox.needNumber .MuiFormControl-root {
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
    margin-bottom: 0;
  }
  .numbersBox.needNumber .MuiFormControl-root .MuiBox-root {
    max-width: 148px;
  }
  .newLocation {
    justify-content: center !important;
  }
  .newLocation .MuiButtonBase-root {
    font-size: 14px;
    line-height: 19px;
    padding: 11px 24px;
  }
  .MuiTypography-root.bigText {
    font-size: 18px;
  }
  .MuiTypography-root.smallText,
  .MuiTypography-root.smallBoldText {
    font-size: 14px;
    word-break: break-word;
  }
  .MuiTypography-root.smallBoldText {
    white-space: nowrap;
  }
  .MuiTypography-root.midText {
    font-size: 18px;
  }
  .flag-item-wrapper {
    opacity: 1 !important;
  }

  .accordion.addons {
    margin-top: 8px;
  }
  .accordion.addons .accordionBtn {
    padding: 16px 0;
    margin: 0;
    opacity: 1;
  }
  .accordion.addons .accordionBtn .MuiBox-root svg {
    display: block;
  }
  .accordion.addons .accordionBtn .MuiTypography-root {
    margin-bottom: 0;
  }
  .accordion.addons .accordionBtn .MuiSvgIcon-root {
    transition: 0.4s all;
  }
  .accordion.addons .accordionBtn.active .MuiSvgIcon-root {
    transform: rotate(-180deg);
  }
  .accordion.addons .accordionBody {
    border-bottom: 1px solid rgba(123, 131, 235, 0.2);
  }
  .accordionContent > .MuiTypography-root {
    display: none;
  }
  .ivrCallToggle .MuiTypography-root {
    margin-bottom: 0;
  }
  .setupBox {
    width: 100% !important;
    max-width: 338px;
    justify-content: space-between !important;
  }
  .setupBox .MuiStack-root {
    width: 47%;
    margin: 0;
  }
  .setupBox .MuiStack-root .MuiTypography-root {
    margin-bottom: 0;
  }
  .accordion.addons .accordionBody .planBox .MuiBox-root {
    margin: 0;
    max-width: 148px;
    width: 100%;
  }
  .accordion.addons .accordionBody .planBox .MuiBox-root .MuiButtonBase-root {
    max-width: 39px;
    min-width: 39px;
  }
  .accordion.addons .accordionBody .planBox .MuiBox-root .MuiInputBase-input {
    height: 26px;
  }
  .accordionContent > .MuiStack-root > .MuiTypography-root {
    display: none;
  }
  .accordionContent > .MuiStack-root > .MuiTypography-root.blockItem {
    display: block;
    margin-bottom: 0;
  }
  .accordionContent > .MuiStack-root {
    margin-top: 0;
  }
  .accordionContent > .MuiStack-root > .MuiFormControl-root {
    margin-top: 0;
    margin-bottom: 24px;
  }
  .accordionContent > .MuiStack-root > .MuiFormControl-root:last-of-type {
    margin-bottom: 0;
  }
  .productBox {
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: 16px !important;
  }
  .productBox > .MuiBox-root {
    margin-right: 0;
    width: 50%;
    max-width: 164px;
    padding: 0 8px;
  }
  .productBox .image-large {
    width: 90px;
    height: 90px;
  }
  .chooseCurrency {
    right: 0 !important;
  }
}

@media screen and (max-width: 375px) {
  .errorPopup {
    max-width: 94% !important;
  }
}

@media screen and (max-width: 1440px) and (min-width: 900px) {
  .details-form-wrapper {
    /* width: calc(100% - 150px); */
    width: 100%;
  }
  .accordion.addons > .MuiBox-root {
    width: 100%;
  }
  .accordion.addons > .MuiBox-root > .MuiBox-root {
    width: 100%;
    flex-wrap: wrap;
  }
  .flagSlider .flagSliderPart {
    width: 50% !important;
  }
}

@media screen and (max-width: 1099px) and (min-width: 923px) {
  .details-form-wrapper {
    height: 65% !important;
  }
}
@media screen and (max-width: 923px) and (min-width: 900px) {
  .details-form-wrapper {
    height: 49vh !important;
  }
}
.css-1mmdh2x-MuiPaper-root-MuiAccordion-root:before,
.css-ittvi2::before {
  display: none !important;
}
.MuiAccordionSummary-content {
  margin: 0px !important;
}
.css-1mmdh2x-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
  min-height: 30px !important;
}

.css-480o17-MuiGrid-root {
  margin-left: 0px !important;
  width: 100% !important;
}
.css-480o17-MuiGrid-root > .MuiGrid-item {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 10px !important;
  margin-right: 20px;
  flex-basis: 100% !important;
}
.css-1l73jyd-MuiGrid-root {
  max-width: 55% !important;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px !important;
}
/* .css-mrkrgg-MuiInputBase-root-MuiOutlinedInput-root{
  width: 24px !important;
} */
/* .css-1lagww-MuiFormControl-root-MuiTextField-root{
  width: 25px !important;
} */
.css-18dnqnd-MuiGrid-root {
  max-width: 45% !important;
}
.css-1qqynwt-MuiTypography-root {
  margin-right: 10px !important;
}
.MuiGrid-root MuiGrid-item MuiGrid-grid-xs-4 css-gj1fbr-MuiGrid-root {
  max-width: 77.33% !important;
}
.text-dummy {
  max-width: 77.33% !important;
  border-left: #8828ff 1px solid;
  border-top: #8828ff 1px solid;
  border-bottom: #8828ff 1px solid;
  border-right: #8828ff 1px solid;
  border-radius: 5px;
}

.css-1q5ei5f {
  overflow: visible !important;
}
/* 
@media (max-width: 900px) and (min-width: 768px) {
  .css-480o17-MuiGrid-root > .MuiGrid-item {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 10px !important;
    margin-right: 20px;
    flex-basis: 100% !important;
  }
} */

@media (max-width: 780px) and (min-width: 661px) {
  .css-1wg20iv {
    left: 50% !important;
    /* margin-top: 140px !important; */
    width: 100% !important;
    top: 75% !important;
  }
}

@media (max-width: 660px) and (min-width: 580px) {
  .css-1wg20iv {
    left: 50% !important;
    /* margin-top: 140px !important; */
    width: 100% !important;
    top: 70% !important;
  }
}

@media (max-width: 579px) {
  .css-1wg20iv {
    left: 50% !important;
    margin-top: 140px !important;
    width: 100% !important;
    top: 50% !important;
  }
}
@media (max-width: 768px) {
  .MuiGrid-item {
    width: 100% !important;
  }
  .MuiGrid-container {
    flex-direction: column;
  }
  .MuiGrid-item {
    margin-bottom: 20px;
  }
  .MuiTypography-h6 {
    font-size: 18px;
  }
  .MuiTypography-body1 {
    font-size: 14px;
  }
  .MuiButton-root {
    width: 100% !important;
  }
  .MuiButton-root {
    margin-bottom: 10px;
  }
  .css-480o17-MuiGrid-root > .MuiGrid-item {
    max-width: 100%;
    margin-top: 15px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 0px !important;
  }
  .css-18dnqnd-MuiGrid-root {
    max-width: 100% !important;
  }
  .css-uj6kyi-MuiButtonBase-root-MuiButton-root {
    max-width: 64px;
  }
}
.css-10hfbhx {
  width: 100% !important;
}
.css-qxfko6 {
  padding: 0px !important ;
}

@media (min-width: 768px) and (max-width: 1276px) {
  .css-480o17-MuiGrid-root > .MuiGrid-item {
    padding-left: 4px !important;
    padding-right: 4px !important;
    padding-top: 4px !important;
    margin-right: 20px;
    flex-basis: 100% !important;
  }
  .css-1tut30k {
    margin-top: 0px !important;
  }
}

/* @media (min-width: 900px) {
  .css-1tut30k {
      margin-top: 0px !important;
  }
}



@media (min-height: 1100px) and (max-height: 1120px) {
  .details-form-wrapper {
    height: 62% !important;
  }
}

@media (min-width: 1200px) {
  .css-1tut30k {
    height: 76% !important;
  }
} */
/* 74%   */

@media (min-width: 900px) {
  /* 30px to 10px for the screen size  */
  .css-fsig2a {
    margin-top: 10px !important;
  }
}

.empty-cart {
  font-family: "DM Sans" !important;
  font-size: 16px !important;
  line-height: 19.2px;
  color: #0c2a52;
}

.empty-back-button {
  border: 1px solid #7b83eb !important;
  background: transparent !important;
}
@media (min-width: 1150px) {
  .css-18dnqnd-MuiGrid-root{
    max-width: 47% !important;
  }
}

@media (max-width: 900px) {
  /* 30px to 10px for the screen size  */
  .css-1w5x880 {
    margin-bottom: 33px !important;
  }
  .css-19t1w01 {
    margin-left: 11px !important;
  }
  .css-1abudc7 {
    margin-bottom: 6px !important;
  }
  .css-oyt386-MuiStack-root {
    margin-top: -36px !important;
  }
  .css-1cwnmh8-MuiTypography-root {
    font-size: 22px !important;
    line-height: 26.4px !important;
  }
  .stepFooter > .css-yl68kw {
    margin-bottom: 10px !important;
  }
  .css-480o17-MuiGrid-root > .MuiGrid-item {
    margin-right: 0px !important;
  }
  .cart-item {
    padding: 10px 20px 10px 10px !important;
  }
  .button-icon {
    font-size: 15px !important;
  }
  .css-1bw0nnu-MuiStep-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.checkout-button-class {
  text-transform: none !important;
  font-size: 14px !important;
  min-width: 98px !important;
  border-radius: 9px;
  border: 1px solid #7b83eb !important;
  background-color: #7b83eb !important;
  color: #fff !important;
  font-family: "Heebo" !important;
  font-weight: 700 !important;
}

.font-700 {
  font-weight: 700 !important;
}

.item-name {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #4b54a2 !important;
  font-family: "Heebo" !important;
  line-height: 15px !important;
}
.item-desc {
  font-size: 14px !important;
  font-weight: 400 !important;
  /* margin-bottom: 15px !important; */
  font-family: "Heebo" !important;
  line-height: 10px !important;
  color: #666666 !important;
}

.item-price-mobile {
  font-size: 14px !important;
  color: #0c2a52 !important;
  font-family: "Heebo" !important;
  line-height: 21px !important;
  font-weight: 700 !important;
}

.css-ahj2mt-MuiTypography-root {
  font-family: "Heebo" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22.4px !important;
}
.font-hebo {
  font-family: "Heebo" !important;
}
.font-dm {
  font-family: "DM Sans" !important;
}
 
@media (max-width: 720px) {
  .custom-popup-box {
    top: 82% !important;
    left: 50% !important;
  }
  .form-control-text-field {
    margin: 0px !important;
  }
}
@media (min-width: 720px) and (max-width: 900px) {
  .custom-popup-box {
      top: 27% !important;
      left: 50% !important;
      padding: 20px !important;
    }
  }
@media (min-width: 900px) and (max-width: 1000px) {
  .custom-popup-box {
    left: 50% !important;
    top: 47% !important;
    padding: 15px !important;
  }
}
@media (min-width: 1000px) and (max-width:1049px) {
  .custom-popup-box {
    left: 50% !important;
    padding: 15px !important;
  }
}
@media (min-width: 1050px) and (max-width:1250px) {
  .custom-popup-box {
    left: 50% !important;
    padding: 15px !important;
  }
}

/*  */

@media (min-width: 900px) {
  .css-25eo7w {
    margin-top: 10px !important;
  }
}

.css-1fdlu6i-MuiButtonBase-root-MuiIconButton-root {
  bottom: 18vh !important;
}

.dm-sans {
  font-family: "Heebo", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
}

.cus-font-color {
  color: #666666 !important;
}

.calculation-box {
  background-color: #fff !important;
  border: #fff !important;
}
.calculation-box-subbox {
  padding: 16px 16px 9px 16px !important;
}
.calculation-box-checkout {
  background-color: #ebf6ff !important;
  border: #ebf6ff !important;
}

.cus-calculation {
  margin-top: 10px !important;
}

.calculation-box-overwrite {
  text-decoration: none !important;
  border: none !important;
}

@media (min-width: 900px) {
  .grid-1 {
    padding: 80px 94px !important;
    width: 43.2% !important;
  }
  .css-jnoh-MuiGrid-root,
  .grid-2 {
    padding: 60px 125px !important;
    max-width: 56% !important;
  }
}

@media (max-width: 901px) {
  .grid-1 {
    padding: 0 !important;
  }
  .grid-2 {
    padding: 20 !important;
  }
}



/* .css-t8sdvn-MuiGrid-root */
.css-c58ldt-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 0 !important;
}

.css-bcfuee-MuiGrid-root > .MuiGrid-item,
.css-kzi48a-MuiGrid-root > .MuiGrid-item {
  padding-left: 0 !important;
  padding-top: 0 !important;
}
.divider {
  width: 1px;
  background-color: #d0d7e3; /* Light gray color */
  height: 100%;
  margin: 0 20px;
}

.css-l450xw-MuiFormControlLabel-root {
  gap: 6px !important;
}

.form-label {
  font-family: "Heebo" !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 10px !important; 
  margin-bottom: 6px !important;
}

.MuiStack-root > .css-1kyuj1h-MuiStack-root {
  line-height: 20px !important;
}

.css-1m9pwf3{
  width: 20px !important;
  height: 20px !important;
}
.css-1397ec1-MuiFormControlLabel-root {
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.css-1b5ehf2-MuiButtonBase-root-MuiRadio-root {
  padding: 0px !important;
  width: 20px !important;
  height: 20px !important;
}

.inside-card-label {
  line-height: 20px !important;
}